import { useEffect, useState } from "react";
import style from "./index.module.scss";
import { Table, Select, Spin, Empty, message } from "antd";

import {
  TimeTitle,
  TableSearch2,
  TableSearch3,
  LevelChart,
  ExportTitle,
  TableNumber,
  DoubleLineDoubleBarChart3,
  TableSearch,
} from "../../../../component/DataBoardStyles";
import { crmTipsMap } from "../../../../assets/public/pageMap";

import {
  getOverviewChannel,
  getOverviewLevel,
  getOverviewTrend,
  getAnalysisTrend,
  getOverviewDownload,
} from "../../../../api/dataBoardApi";

import {
  useGetChannelList,
  useGetShoplList,
} from "../../../../assets/public/dataBoard";
import {
  ChannelIcon,
  TextWithQuestion,
} from "../../../../component/MiniStyles";
import dayjs from "dayjs";
import handScrollTop from "@/crm/assets/public/handscrollTop";

// 进度条颜色
const progressColorList = [
  "#3BA0FF 0%, rgba(59,160,255,0.25) 100%",
  "#36CBCB 0%, rgba(19,194,194,0.25) 100%",
  "#722ED1 0%, rgba(114,46,209,0.25) 100%",
  "#4DCB73 0%, rgba(77,203,115,0.25) 100%",
  "#2F54EB 0%, rgba(47,84,235,0) 100%",
];

// 等级金字塔全部会员下拉框
const gradeListMemberOptions = [
  { value: "all", label: "全部会员" },
  { value: "deal", label: "已购会员" },
  { value: "no_deal", label: "未购会员" },
];

// 等级金字塔默认搜索内容
const defaultGradeSearchInfo = {
  platformType: "all",
  shopId: "0",
  buyType: "all",
};

// 等级金字塔options用
const levelChartColorList = [
  "#0958D9",
  "#1677FF",
  "#69B1FF",
  "#91CAFF",
  "#BAE0FF",
];
const levelChartWidthList = [8, 16, 24, 32, 40];

// 全渠道会员数据总览
const MemberTopSection = () => {
  const [gradeSearchInfo, setGradeSearchInfo] = useState(
    defaultGradeSearchInfo
  );
  const [progressList, setProgressList] = useState<any[]>([]);
  const [gradeList, setGradeList] = useState([]);
  const { channelList } = useGetChannelList("member");
  const { shopAllList, getShopList } = useGetShoplList("member");
  const [loadingMap, setLoadingMap] = useState({
    progressStatus: false,
    levelStatus: false,
  });

  useEffect(() => {
    getProgressListInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getGradeListInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(gradeSearchInfo)]);

  // loading时间
  const changeLoading = (name: string, status: boolean) => {
    setLoadingMap({ ...loadingMap, [name]: status });
  };

  // 获得全渠道会员分域一览列表
  const getProgressListInfo = async () => {
    const res = await getOverviewChannel();
    if (res?.code === 200) {
      setProgressList(res?.data || []);
    } else {
      setProgressList([]);
    }
  };

  // 获得等级金字塔列表
  const getGradeListInfo = async () => {
    changeLoading("levelStatus", true);
    const res = await getOverviewLevel({
      ...gradeSearchInfo,
      platformType: gradeSearchInfo.platformType.toLowerCase(),
    });
    if (res?.code === 200) {
      const arr =
        res?.data?.map((e: any, index: number) => ({
          level: `${e.memberName}`,
          width: levelChartWidthList[index],
          color: levelChartColorList[index],
          num: e.memberNum,
          rate: e.memberNumRate,
        })) || [];
      setGradeList(arr.reverse());
    } else {
      setGradeList([]);
    }
    changeLoading("levelStatus", false);
  };

  // 渠道图标+渠道名称
  const ChannelIconName = ({
    channelName,
    code,
  }: {
    channelName: string;
    code: string;
  }) => {
    const nameList = channelName ? channelName.split("&") : [];

    const NameIcon = ({ name }: { name: string }) => {
      return (
        <div>
          {code && (
            <ChannelIcon
              channelType={code.toUpperCase()}
              style={{
                width: "16px",
                height: "16px",
                transform: "translateY(-2px)",
              }}
            />
          )}
          <span style={{ marginLeft: "2px" }}>{name}</span>
        </div>
      );
    };

    return (
      <div style={{ display: "flex" }}>
        {nameList.map((e: string, index) => (
          <>
            <NameIcon name={e} />
            {index + 1 < nameList.length && (
              <span style={{ margin: "0 4px" }}>&</span>
            )}
          </>
        ))}
      </div>
    );
  };

  // 进度条
  const ProgressBar = ({ info, index }: { info: any; index: number }) => {
    const { name, num, rate = 0, code } = info;
    const progressRate = Number(rate) > 100 ? 100 : Number(rate);

    return (
      <div style={{ marginBottom: "18px" }}>
        <div className={style["progress-text"]}>
          <ChannelIconName channelName={name} code={code} />
          <div className={style["num"]}>
            {Number(num).toLocaleString() || 0}
          </div>
        </div>
        <div className={style["progress"]}>
          <div
            className={style["rate"]}
            style={{
              width: `${progressRate}%`,
              background: `linear-gradient(-90deg, ${progressColorList[index]}), var(--background1)`,
            }}
          />
          <div
            className={style["dot-top"]}
            style={{
              left: `calc( ${progressRate}% - 1px)`,
              background: `linear-gradient(-90deg, ${progressColorList[index]}), var(--background1)`,
            }}
          />
          <div
            className={style["dot-bottom"]}
            style={{
              left: `calc( ${progressRate}% - 1px)`,
              background: `linear-gradient(-90deg, ${progressColorList[index]}), var(--background1)`,
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <TimeTitle
        title="全渠道会员数据总览"
        tips={crmTipsMap.get("全渠道会员数据总览")}
      />
      <div className={style["top-section-bgd"]}>
        {/* 左侧卡片 */}
        <div className={style["left-section"]}>
          <div className={style["title-bgd"]}>
            <div>全渠道会员分域一览</div>
            <div className={style["num"]}>
              {Number(progressList[0]?.num || 0).toLocaleString()}
            </div>
          </div>
          <div className={style["card"]}>
            {progressList?.length > 0 &&
              progressList.map(
                (item: any, index) =>
                  index < 6 &&
                  index > 0 && (
                    <ProgressBar key={item.name} info={item} index={index} />
                  )
              )}
            {progressList?.length === 0 && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                style={{ margin: "84px auto" }}
              />
            )}
          </div>
        </div>
        {/* 右侧卡片 */}
        <div className={style["right-section"]}>
          <Spin spinning={loadingMap.levelStatus}>
            <div className={style["title-bgd"]}>
              <div>品牌全渠道等级会员占比统计</div>
              <div>
                <Select
                  size="small"
                  value={gradeSearchInfo.platformType}
                  options={[
                    { value: "all", label: "全部平台" },
                    ...channelList,
                  ]}
                  onChange={(platformType) => {
                    getShopList(null, platformType);
                    setGradeSearchInfo({
                      ...gradeSearchInfo,
                      platformType,
                      shopId: "0",
                    });
                  }}
                  style={{ width: "120px" }}
                />
                <Select
                  size="small"
                  value={gradeSearchInfo.shopId}
                  options={shopAllList}
                  onChange={(shopId) =>
                    setGradeSearchInfo({ ...gradeSearchInfo, shopId })
                  }
                  style={{ width: "120px", margin: "0 8px" }}
                />
                <Select
                  size="small"
                  options={gradeListMemberOptions}
                  value={gradeSearchInfo.buyType}
                  onChange={(buyType) =>
                    setGradeSearchInfo({ ...gradeSearchInfo, buyType })
                  }
                  style={{ width: "120px" }}
                />
              </div>
            </div>
            <LevelChart chartId="levelChart" options={gradeList} />
          </Spin>
        </div>
      </div>
    </div>
  );
};

// 全渠道会员发展趋势分析
const MemberLineSection = () => {
  // 柱状图options
  const [chartOptions, setChartOptions] = useState([]);

  // 搜索
  const getChartInfo = async (e: any) => {
    const res = await getOverviewTrend({ ...e });

    if (res?.code === 200) {
      const arr =
        res?.data?.map((item: any) => ({
          time: item.xbizDate,
          bar1: item.newMemberPurchaseNum || 0,
          rate1: Number(item.newMemberPurchaseNumTbRate) || 0,
          rateNum1: item.newMemberPurchaseNumTb || 0,
          bar2: item.addMemberNotDeal || 0,
          rate2: Number(item.addMemberNotDealTbRate) || 0,
          rateNum2: item.addMemberNotDealTb || 0,
        })) || [];
      setChartOptions(arr);
    } else {
      setChartOptions([]);
    }
  };

  return (
    <div style={{ marginTop: "24px" }}>
      <TimeTitle
        title="全渠道会员发展趋势分析"
        tips={crmTipsMap.get("全渠道会员发展趋势分析")}
        noTime
      />
      <TableSearch2
        onFinish={getChartInfo}
        type="member"
        background="transparent"
      />
      <div style={{ marginTop: "24px" }}>
        <DoubleLineDoubleBarChart3
          chartId="salesOverview"
          options={chartOptions}
        />
      </div>
    </div>
  );
};

// 全渠道会员资产明细分析
const MemberTableSection = () => {
  const [searchInfo, setSearchInfo] = useState<any>({});
  const [tableList, setTableList] = useState([]);

  // 搜索
  const handSearch = async (e: any) => {
    setSearchInfo({ ...e });
    const res = await getAnalysisTrend({
      ...e,
      memberCardId: "0",
      platformType: e.platformType || "all",
      shopId: e.shopId || "0",
    });
    if (res?.code === 200) {
      setTableList(res?.data || []);
    } else {
      setTableList([]);
    }
  };

  // 导出
  const handDownload = async () => {
    const params = {
      query: {
        ...searchInfo,
        shopId: searchInfo.shopId || "0",
        platformType: searchInfo.platformType || "all",
        memberCardId: "0",
      },
      file: {
        taskName: `全渠道会员资产明细分析-会员资产分析-${dayjs().format(
          "YYYYMMDDHHmmss"
        )}`, //任务名称 + 日期时间
        taskLocation: "看板/基础看板/会员资产分析", //页面位置
        //文件上传信息
        service: "eshop", //服务编码 （eshop 电商，zt 中台）
        name: "全渠道会员资产明细分析", //文件名
        originalFilename: `全渠道会员资产明细分析-会员资产分析-${dayjs().format(
          "YYYYMMDDHHmmss"
        )}.csv`, //文件原名
        contentType: "text/csv", //文件类型
      },
    };
    const res = await getOverviewDownload(params);
    if (res?.code === 200) {
      message.success("导出中，请到下载中心查看进度");
    } else {
      message.success("导出失败");
    }
  };

  // 表格问号标题
  const TableTitle = ({ children }: { children: string }) => (
    <TextWithQuestion
      black
      title={children}
      tips={crmTipsMap.get(`会员资产分析_${children}`)}
    />
  );

  return (
    <div style={{ marginTop: "24px" }}>
      <ExportTitle
        title="全渠道会员资产明细分析"
        tips={crmTipsMap.get("全渠道会员资产明细分析")}
        handExport={handDownload}
        permission="会员资产分析导出"
      />
      <TableSearch
        onFinish={handSearch}
        disabledNum={1}
        type="member"
        background="transparent"
      />

      <Table
        dataSource={tableList}
        pagination={false}
        style={{ marginTop: "24px" }}
        scroll={{ x: 1500, y: 500 }}
        rowClassName={style["table-row"]}
        expandable={{
          expandedRowClassName: () => {
            return style["expanded-row-expended"];
          },
        }}
        rowKey="id">
        <Table.Column width={46} fixed="left" />
        <Table.Column
          className={style["table-column-blue"]}
          title="时间"
          dataIndex="bizDate"
          width={110}
          fixed="left"
        />
        <Table.Column
          className={style["table-column-green"]}
          title="全部"
          dataIndex="name"
          width={140}
          fixed="left"
        />
        <Table.Column
          title={<TableTitle>全部会员总数</TableTitle>}
          dataIndex="memberNum"
          render={(value) => <TableNumber value={value} />}
          width={140}
        />
        <Table.Column
          title={<TableTitle>新增会员</TableTitle>}
          dataIndex="addMemberNum"
          render={(value) => <TableNumber value={value} />}
          width={110}
        />
        <Table.Column
          title={<TableTitle>新会员转化率</TableTitle>}
          dataIndex="addMemberRate"
          render={(value) => <TableNumber value={value} rate />}
          width={140}
        />
        <Table.Column
          title={<TableTitle>全部已购会员</TableTitle>}
          dataIndex="memberPurchaseNum"
          render={(value) => <TableNumber value={value} />}
          width={140}
        />
        <Table.Column
          title={<TableTitle>已购会员占比</TableTitle>}
          dataIndex="memberPurchaseRate"
          render={(value) => <TableNumber value={value} rate />}
          width={140}
        />
        <Table.Column
          title={<TableTitle>全部未购会员</TableTitle>}
          dataIndex="memberNotDealNum"
          render={(value) => <TableNumber value={value} />}
          width={140}
        />
        <Table.Column
          title={<TableTitle>活跃会员</TableTitle>}
          dataIndex="customerActiveMemberNum"
          render={(value) => <TableNumber value={value} />}
          width={110}
        />
        <Table.Column
          title={<TableTitle>沉默会员</TableTitle>}
          dataIndex="customerSilentMemberNum"
          render={(value) => <TableNumber value={value} />}
          width={110}
        />
        <Table.Column
          title={<TableTitle>流失会员</TableTitle>}
          dataIndex="customerLoseMemberNum"
          render={(value) => <TableNumber value={value} />}
          width={110}
        />
      </Table>
    </div>
  );
};

const MemberAssetAnalysis = () => {
  useEffect(() => {
    handScrollTop();
  }, []);

  return (
    <div className={style["MemberAssetAnalysis-bgd"]}>
      {/* 全渠道会员数据总览 */}
      <MemberTopSection />
      {/* 全渠道会员发展趋势分析 */}
      <MemberLineSection />
      {/* 全渠道会员资产明细分析 */}
      <MemberTableSection />
    </div>
  );
};

export default MemberAssetAnalysis;
