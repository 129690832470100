import jd from "../images/jd.png";
import tm from "../images/tm.png";
import dy from "../images/dy.png";
import wx from "../images/wx.png";
import yz from "../images/yz.png";
import jd_blue from "../images/jd_blue.png";
import tm_blue from "../images/tm_blue.png";
import dy_blue from "../images/dy_blue.png";
import wx_blue from "../images/wx_blue.png";
import yz_blue from "../images/yz_blue.png";
import offlineShop from "../images/offlineShop.png";
import crownFilleds from "../images/crownFilleds.png";
import frownFilled from "../images/frownFilled.png";
import hookImage from "../images/hookImage.png";
import mehFilled from "../images/mehFilled.png";
import shopCar from "../images/shopCar.png";
import smileFilled from "../images/smileFilled.png";
import user from "../images/user.png";

// 渠道图标map
export const channelIconMap = new Map([
  ["JD", jd],
  ["TM", tm],
  ["DY", dy],
  ["WX", wx],
  ["YZ", yz],
  ["MD", offlineShop],
  ["JD_blue", jd_blue],
  ["TM_blue", tm_blue],
  ["DY_blue", dy_blue],
  ["WX_blue", wx_blue],
  ["YZ_blue", yz_blue],
]);

// 其他图标
export const iconMap = new Map([
  ["crownFilleds", crownFilleds],
  ["frownFilled", frownFilled],
  ["hookImage", hookImage],
  ["mehFilled", mehFilled],
  ["shopCar", shopCar],
  ["smileFilled", smileFilled],
  ["user", user],
]);

// 下拉框id选择列表
export const searchIdMap = new Map([
  ["全渠道Id", "ONE_ID"],
  ["手机号", "PHONE"],
  ["平台id", "PLATFORM_ID"],
  ["订单号", "ORDER_ID"],
]);

export const searchIdList = [
  { label: "手机号", value: "PHONE" },
  { label: "OneID", value: "ONE_ID" },
];

// 权限map
export const permissionMap = new Map([
  ["会员卡管理新建", "crm:member:card:add"],
  ["会员卡管理编辑", "crm:member:card:update"],
  ["会员卡管理查看", "crm:member:card:details"],
  ["会员卡管理启用", "crm:member:card:delete"],
  ["会员卡管理列表", "crm:member:card:list"],
  ["等级变更记录查看", "crm:member:grade:page"],
  ["等级变更记录复制", "crm:member:grade:copy"],
  ["入退会记录查看", "crm:member:change:page"],
  ["入退会记录复制", "crm:member:change:copy"],
  ["成长值变更记录查看", "crm:growth-value:change:page"],
  ["成长值变更记录复制", "crm:growth-value:change:copy"],
  ["积分变更记录查看", "crm:point:change:page"],
  ["积分变更记录复制", "crm:point:change:copy"],
  ["会员交易分析导出", "cdpdata:member:trade:export"],
  ["会员资产分析导出", "cdpdata:member:overview:export"],
  ["客户交易分析导出", "cdpdata:customer:trade:export"],
  ["客户资产分析导出", "cdpdata:customer:assets:export"],
]);

// tips
export const crmTipsMap = new Map([
  [
    "体系编码",
    "体系编码目的为若会员卡卡数较多，可以通过编码进行查询。会员编码不会显示给C端用户。体系编码可以随意设置英文+数字。方便记忆即可。(创建后不可编辑修改)",
  ],
  ["全渠道客户总览", "全渠道的客户和全渠道会员的数量以及日、周、月的变化情况"],
  [
    "全渠道客户",
    "累计的产生有效订单的全部客户，为多个平台去重后的客户总和，例如京东+天猫+抖音+有赞+门店的去重的总客户数",
  ],
  ["全渠道已购会员", "统计周期内购买了1笔及以上订单的会员"],
  ["全渠道未购会员", "统计周期内购没有产生订单的会员"],
  ["全渠道客户_昨日新增", "昨日新增全渠道客户数"],
  ["全渠道客户_本周新增", "本周新增全渠道客户数"],
  ["全渠道客户_本月新增", "本月新增全渠道客户数"],
  ["全渠道已购会员_昨日新增", "昨日新增全渠道已购会员数"],
  ["全渠道已购会员_本周新增", "本周新增全渠道已购会员数"],
  ["全渠道已购会员_本月新增", "本月新增全渠道已购会员数"],
  ["全渠道未购会员_昨日新增", "昨日新增全渠道未购会员数"],
  ["全渠道未购会员_本周新增", "本周新增全渠道未购会员数"],
  ["全渠道未购会员_本月新增", "本月新增全渠道未购会员数"],
  [
    "客户类型分布",
    "■ 查看会员和非会员的结构分布状况\n●会员已购：产生有效订单的会员\n●会员未购：未产生有效订单的会员\n●非会员已购：产生有效订单的非会员",
  ],
  [
    "客户活跃度分布",
    "■ 查看客户活跃和非活跃程度\n●活跃客户：截止到数据更新日期，近180天内产生过有效订单的客户\n●沉默客户：截止到数据更新日期，近180天内未购买，前180天到365天内产生过有效订单的客户\n●流失客户：截止到数据更新日期，近365天内未购买，前365天前产生过有效订单的客户",
  ],
  ["会员等级分布", "查看会员的对应不同等级分布状况"],
  ["全渠道销售总览", "全渠道销售情况数据的总览以及全渠道近30天的消费总趋势"],
  [
    "全渠道销售总览_销售额",
    "全渠道的销售额金额，为多个平台累计的有效订单的总销售金额",
  ],
  ["全渠道销售总览_新增会员", "全渠道的新增会员数，重复开卡也视为新增会员"],
  [
    "全渠道销售总览_消费客户数",
    "全渠道的消费客户数，为去重后多个平台产生效订单数的客户数",
  ],
  [
    "全渠道销售总览_消费会员数",
    "全渠道的消费会员数，为去重后多个平台产生效订单数的会员数",
  ],
  [
    "全渠道客户资产数据总览",
    "展示各个生命周期下客户群体数量，帮助品牌精准地感知全域客户资产的对比状况",
  ],
  [
    "客户资产分析_全渠道客户",
    "为京东、天猫、抖音等多个平台的获取为历史时间内已购客户的非会员+已购客户会员+未购会员去重后的全部客户数",
  ],
  ["客户资产分析_活跃客户", "根据全渠道客户ID去重统计最近180天内有购买的客户"],
  [
    "客户资产分析_沉默客户",
    "根据全渠道客户ID去重统计最近180天无购买，往前180天至365天有购买的客户。",
  ],
  [
    "客户资产分析_流失客户",
    "根据全渠道客户ID去重统计最近365天无购买，往前365天前有购买的客户。",
  ],
  [
    "客户资产分析_已购客户",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的累计时间内获取产生有效订单的客户",
  ],
  [
    "客户资产分析_已购会员",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的累计时间内获取产生有效订单的会员人数",
  ],
  [
    "客户资产分析_已购非会员",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的累计时间内产生有效订单的人数-已购会员人数",
  ],
  ["客户资产分析_当期已购会员", "统计数据更新日期，新客会员+老客会员"],
  [
    "客户资产分析_新客会员",
    "截止到数据更新日期，历史未产生过购买在统计日(筛选日期/数据更新日期)内产生购买的会员，为新客会员",
  ],
  [
    "客户资产分析_老客会员",
    "截止到数据更新日期，历史产生过购买在统计日(筛选日期/数据更新日期)内产生购买的会员，为老客会员",
  ],
  ["客户资产分析_全部非会员", "统计数据更新日期，新客非会员+老客非会员"],
  [
    "客户资产分析_新客非会员",
    "截止到数据更新日期，历史未产生过购买在统计日(筛选日期/数据更新日期)内产生购买的客户，且身份不是会员，为新客非会员",
  ],
  [
    "客户资产分析_老客非会员",
    "截止到数据更新日期，历史产生过购买在统计日(筛选日期/数据更新日期)内产生购买的客户，且身份不是会员，为老客非会员",
  ],

  [
    "全渠道客户资产明细数据",
    "展示各个生命周期下的客户群体数量，帮助品牌精准地感知全域客户的整体情况以及平台、品牌、店铺级全部客户资产的详细状况。",
  ],
  ["全渠道客户交易数据总览", "客户群体的数量分析，展示有价值群体的占比量"],
  ["全渠道客户购买趋势", "围绕特定周期查看客户购买趋势变化"],
  ["全渠道客户交易明细数据", "全渠道客户交易明细详细展示"],
  [
    "全渠道会员数据总览",
    "展示全渠道会员各个平台的分布状况，全域会员体系平台分层来源分析",
  ],
  ["全渠道会员发展趋势分析", "针对选定时间范围，进行新增会员和会员首购的统计"],
  [
    "全渠道会员资产明细分析",
    "展示各个生命周期下的会员数量，帮助品牌精准地感知全域会员的整体情况以及平台、品牌、店铺级全部会员资产的详细状况。",
  ],
  [
    "全渠道会员交易数据总览",
    "展示会员群体的数量分析，帮助品牌把握高价值会员群体的占比量",
  ],
  [
    "全渠道会员购买趋势",
    "围绕特定周期查看客户购买趋势变化，和交易明细指标的详细展示",
  ],
  [
    "全渠道会员交易明细数据",
    "围绕特定周期查看客户购买趋势变化，和交易明细指标的详细展示",
  ],
  ["RF分析", "通过购买频次和购买间隔判断客户的忠诚度"],
  ["RM分析", ":通过购买频次和购买金额判断高价值客户"],
  [
    "RFM分析",
    "通过分析客户最近一次购买时间、购买频率、购买金额来为企业衡量重要价值客户",
  ],
  [
    "复购间隔分析",
    "统计时间内，发生多次购买的客户在相邻两次购买行为之间的平均时间间隔",
  ],
  ["R≤90天", "最近一次消费间隔小于90天"],
  ["90天<R≤180天", "最近一次消费间隔大于90天小于等于180天"],
  ["180天<R≤270天", "最近一次消费间隔大于180天小于等于270天"],
  ["270天<R≤365天", "最近一次消费间隔大于270天小于等于365天"],
  ["365天<R≤720天", "最近一次消费间隔大于365天小于等于720天"],
  ["720天<R≤1095天", "最近一次消费间隔大于720天小于等于1095天"],
  ["M≤100", "购买金额小于等于100元"],
  ["100<M≤200", "购买金额大于100小于等于200元"],
  ["200<M≤300", "购买金额大于200小于等于300元"],
  ["300<M≤400", "购买金额大于300小于等于400元"],
  ["400<M≤500", "购买金额大于400小于等于500元"],
  ["500<M≤600", "购买金额大于400小于等于500元"],
  ["600<M≤700", " 购买金额大于600小于等于700元"],
  ["700<M≤800", "购买金额大于700小于等于800元"],
  ["800<M≤900", "购买金额大于800小于等于900元"],
  ["900<M≤1000", "购买金额大于900小于等于1000元"],
  ["M>1000", "购买金额大于1000元"],
  ["F=1", "统计日期内（最近3年 / 历史全量）购买1次"],
  ["F=2", "统计日期内（最近3年 / 历史全量）购买2次"],
  ["F=3", "统计日期内（最近3年 / 历史全量）购买3次"],
  ["F=4", "统计日期内（最近3年 / 历史全量）购买4次"],
  ["F≥5", "统计日期内（最近3年 / 历史全量）购买大于等于5次"],
  [
    "重要价值客户",
    "R低，F高，M高属于企业忠诚用户，按照生命周期看，需要一定的营销策略延长用户忠诚时间。",
  ],
  [
    "重要保持客户",
    "R低，F低，M高最近一次消费时间较远，但消费频次和总金额较高，说明是一段时间没有光顾的忠实客户（即将流失），需要主动联系进行挽回。",
  ],
  [
    "重要发展客户",
    "R低，F低，M高近期有发生购买行为，消费金额高于多数用户，但是购买频率较低，需要刺激用户进行回购，增加对品牌的忠诚。",
  ],
  [
    "重要挽留客户",
    "R高，F低，M高历史消费金额较高，但是购买频率较低，近期没有发生购买行为，已经趋于流失，最核心的需求是刺激用户进行回购，增加对产品及品牌的印象。",
  ],
  ["潜力客户", "R低，F高，M低交易次数大，日最近有交易，需要挖掘此类客户。"],
  ["有推广价值客户", "R低，F低，M低最近有交易，接触的新客户，有推广价值。"],
  ["一般维持客户", "R高，F高，M低交易次数多，但是贡献不大，一般维持。"],
  ["流失客户", "R高，F低，M低 ,FM值均低过平均值，最近也没再购买相当于流失。"],
  [
    "复购平均间隔分析",
    "统计时间内， 发生多次购买的客户在相邻两次购买行为之间的平均时间间隔",
  ],
  [
    "会员资产分析_全部会员总数",
    "截止最新统计日期的会员人数总和（剔除退会人数）",
  ],
  [
    "会员资产分析_新增会员",
    "自统计时间内开卡入会的会员（统计时间为选定的时间）",
  ],
  [
    "会员资产分析_新会员转化率",
    "新入会&购买/新入会 x 100%（Prospects CVR rate）",
  ],
  [
    "会员资产分析_全部已购会员",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的累计时间内产生有效订单的会员",
  ],
  [
    "会员资产分析_已购会员占比",
    "会员购买人数/总购买人数 x 100%（Binding rate）",
  ],
  [
    "会员资产分析_全部未购会员",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的累计时间内未产生有效订单的会员",
  ],
  [
    "会员资产分析_活跃会员",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的累计时间内统计最近180天内有购买的会员",
  ],
  [
    "会员资产分析_沉默会员",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的累计时间内统计最近180天无购买，往前180天到365天内有购买的会员",
  ],
  [
    "会员资产分析_流失会员",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的累计时间内统计最近365天无购买，往前365天前有购买的会员",
  ],
  [
    "会员交易分析_GMV",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的统计时间范围内会员销售额,创建订单当日客户身份为会员",
  ],
  [
    "会员交易分析_退单金额1,[object Object],（当日退款）",
    "当日的退款金额。（常规统计日、月、年维度）",
  ],
  [
    "会员交易分析_退单金额2,[object Object],（当日成交退款）",
    "当日成交的订单，产生的退款金额。（仅统计月、年维度，且在T+1月/T+1年的T+15日出数据，如4月15日出3月数据，1月15日出前一年整年，及前1年12月的数据）",
  ],
  [
    "会员交易分析_购买人数",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的统计时间范围内创建有效订单当日客户身份为会员，该类订单按照陆泽购买人数规则计算会员购买人数。",
  ],
  [
    "会员交易分析_订单数",
    "客户创建的有效订单的主订单数，该类订单按照陆泽订单数规则计算全店订单数。",
  ],
  ["会员交易分析_购买频次", "当前统计维度内的 订单数/购买人数"],
  [
    "会员交易分析_客单价",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的统计时间范围内会员客单价,会员GMV/会员购买人数",
  ],
  [
    "会员交易分析_笔单价",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的统计时间范围内会员笔单价，会员GMV/会员订单数",
  ],
  ["会员交易分析_笔件数", "商品数量/订单数 ，一笔订单中购买的产品件数"],
  ["会员交易分析_件单价", "笔单价/笔件数，一笔订单中每一件产品的平均价格"],
  [
    "会员交易分析_新入会",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的统计时间范围内新入会人数，需要统计出会员新客、会员老客",
  ],
  [
    "客户资产分析_全部客户",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的累计时间范围内获取的已购客户的非会员+已购客户会员+未购会员的全部客户数",
  ],
  [
    "客户资产分析_全部未购会员",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的累计时间内身份为会员的，并在累计时间内未产生有效订单的人数（全部会员-已购会员）",
  ],
  [
    "客户资产分析_全部已购客户",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的累计时间范围内获取的产生有效订单的全部客户数",
  ],
  ["客户资产分析_活跃客户", "根据全渠道客户ID去重统计最近180天有购买的客户"],
  [
    "客户资产分析_沉默客户",
    "根据全渠道客户ID去重统计最近180天无购买，往前180天至365天有购买的客户",
  ],
  [
    "客户资产分析_流失客户",
    "根据全渠道客户ID去重统计最近365天无购买，往前365天前有购买的客户",
  ],
  ["客户资产分析_一次客", "历史上只购买了一笔订单的客户"],
  ["客户资产分析_多次客", "历史上购买了2笔及以上订单的客户"],
  [
    "客户资产分析_全部已购会员",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的累计时间内获取产生有效订单的会员人数",
  ],
  [
    "客户资产分析_全部已购非会",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的累计时间内产生有效订单的人数-已购会员人数",
  ],
  ["客户资产分析_二回率", "（新客&二购)/新客 x 100%"],
  ["客户资产分析_回购率", "（去年&今年购买）/去年购买 x 100% "],
  [
    "客户资产分析_历史回购率",
    "（去年之前购买∩今年统计期）/去年之前购买 x 100%",
  ],
  [
    "客户资产分析_复购率",
    "当年产生过2笔订单以上的客户/当年产生过1笔订单以上的客户",
  ],
  [
    "客户资产分析_全部会员",
    "根据全渠道客户ID去重统计京东、天猫、抖音等多个平台的累计时间内是会员的人数",
  ],
  [
    "客户资产分析_GMV",
    "统计时间范围内，全渠道的销售额金额，为多个平台产生的有效订单的总销售金额",
  ],
  ["客户资产分析_购买人数", "统计时间范围内，产生有效订单的客户数"],
  ["客户资产分析_一次客", "历史购买过一次订单的人群，根据渠道拆分"],
  [
    "数据总览_全渠道客户",
    "为京东、天猫、抖音等多个平台的获取为历史时间内已购客户的非会员+已购客户会员+未购会员去重后的全部客户数",
  ],
  [
    "数据总览_全渠道已购会员",
    "累计多个平台产生有效订单的去重后的会员总和，例如京东+天猫+抖音的去重的总会员数",
  ],
  [
    "数据总览_全渠道未购会员",
    "累计多个平台未产生有效订单的去重后的会员总和，例如京东+天猫+抖音的去重的总会员数",
  ],
  [
    "全渠道销售总览销售额",
    "统计时间范围内，全渠道的销售额，为多个平台产生的有效订单的总销售金额",
  ],
  [
    "全渠道销售总览已购客户数",
    "统计时间范围内，全渠道的消费客户数，为去重后多个平台产生有效订单的客户数",
  ],
  [
    "全渠道销售总览已购会员数",
    "统计时间范围内，全渠道的消费会员数，为去重后多个平台产生有效订单的会员数",
  ],
  [
    "全渠道销售总览新增会员数",
    "统计时间范围内，全渠道昨日新增会员数，重复开卡也视为新增会员",
  ],
  [
    "客户交易分析_GMV",
    "统计时间范围内，全渠道的销售额金额，为多个平台产生的有效订单的总销售金额",
  ],
  [
    "客户交易分析_退单金额1,[object Object],（当日退款）",
    "当日的退款金额。（常规统计日、月、年维度）",
  ],
  [
    "客户交易分析_退单金额2,[object Object],（当日成交退款）",
    "当日成交的订单，产生的退款金额。（仅统计月、年维度，且在T+1月/T+1年的T+15日出数据，如4月15日出3月数据，1月15日出前一年整年，及前1年12月的数据）",
  ],
  ["客户交易分析_购买人数", "统计时间范围内，产生有效订单的客户数"],
  ["客户交易分析_订单数", "统计时间范围内，客户创建的有效订单的主订单数"],
  ["客户交易分析_客单价", "统计时间范围内，销售额/购买人数"],
  ["客户交易分析_购买频次", "当前统计维度内的 订单数/购买人数"],
  ["客户交易分析_笔单价", "统计时间范围内，销售额/订单数"],
  ["客户交易分析_笔件数", "商品数量/订单数 ，一笔订单中购买的产品件数"],
  ["客户交易分析_件单价", "笔单价/笔件数，一笔订单中每一件产品的平均价格"],
  ["客户交易分析_一次客", "历史购买过一次订单的人群，根据渠道拆分"],
]);
