import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import Menu from '../../../component/layout/Menu';
import SubMenu from '../../../component/layout/SubMenu';
import { Outlet } from "react-router-dom";
import { state, setMenu } from '../../../store';
import { useSnapshot } from 'valtio';
import { LzMenu } from '../../../Types/LzMenu';
import LZHeader from '../../../component/layout/Header';
import { Spin } from 'antd';
import busEvent from '../../../utils/bus'
const { Header, Content } = Layout;




function LZLayout() {
  const snap = useSnapshot(state)

  //菜单是否收回
  const [thin, setThin] = useState(false)

  //展开菜单
  const arrowSubMenu = (e: boolean) => {
    setThin(!e)
  }
  useEffect(() => {
    // 全局储存菜单信息
    busEvent.on('subMenuArrow', arrowSubMenu)
  }, [])
  if (snap.menu && state.menu.length > 0) {
    return (
      <Layout className="lz_container">
        <Menu />
        {/* {(snap.menu[snap.currentMenuIndex] && snap.menu[snap.currentMenuIndex].children) && <SubMenu />} */}
        <SubMenu />
        <Layout>
          <LZHeader></LZHeader>
          <Content className='content-box'>
            <Content style={{ background: 'var(--background1)', overflowY: 'auto', height: '100%', scrollbarWidth: 'none' }} >
              <Outlet />
            </Content>
          </Content>
        </Layout>
      </Layout>
    );
  } else {
    return <div></div>
  }


}

export default LZLayout;